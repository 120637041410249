import React from 'react'
import './ViewThree.css'
import projectone from './mulavoor.jpeg'
import projecttwo from './klm.jpeg'
import projectthree from './Laksadweep.jpeg'
import projectfour from './Pathanapuram.jpeg'
import projectfive from './Nellad.jpeg'
import projectsix from './Munnar.jpeg'
import 'animate.css';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom'
import { MdDoubleArrow } from "react-icons/md";




function ViewThree() {
    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures the animation triggers only once
      });


      
   
    

  return (
    <div className='view-three-main'>
        <div ref={ref} className={`animated-element ${inView ? 'animate' : ''}`} >
            <div className='view-three-head'>
                <h1 className='animate__animated animate__fadeInUp'>FEATURED <br /> PROJECTS</h1>
            </div>
        </div>
        
        <div className='view-three-content'>
            <div className='view-three-sub-content' >
                <div className='view-three-content-one'>
                    <img src={projectone} alt="" />
                    <div className='bg-arrow-main'>
                        <div className='bg-doublearrow'>
                            <h1 > <MdDoubleArrow/> </h1>
                        </div> 
                    </div>
                    
                    
                    <h1>4 BHK CONTEMPORARY STYLE HOUSE</h1>
                    <p>MULOOR , KERALA</p>
                </div>
                <div className='view-three-content-one'
                    
                
                >
                    <img src={projecttwo} alt="" />
                    <div className='bg-arrow-main'>
                    <div className='bg-doublearrow'>
                            <h1> <MdDoubleArrow/> </h1>
                        </div> 
                    </div>
                    <h1>4 BHK CONTEMPORARY STYLE HOUSE</h1>
                    <p>KOTHAMANGALAM , KERALA</p>
                </div>
                <div className='view-three-content-one'>
                    
                    <img src={projectthree} alt="" />
                    <div className='bg-arrow-main'>
                    <div className='bg-doublearrow'>
                            <h1> <MdDoubleArrow/> </h1>
                        </div> 
                    </div>
                    

                    <h1>4 BHK CONTEMPORARY STYLE HOUSE</h1>
                    <p>LAKSHADEEP</p>
                </div>
                <div className='view-three-content-one'>
                    
                    <img src={projectfour} alt="" />
                    <div className='bg-arrow-main'>
                    <div className='bg-doublearrow'>
                            <h1> <MdDoubleArrow/> </h1>
                        </div> 
                    </div>
                    

                    <h1>4 BHK CONTEMPORARY STYLE HOUSE</h1>
                    <p>PATHANAPURAM , KERALA</p>
                </div>
                <div className='view-three-content-one'>
                    
                    <img src={projectfive} alt="" />
                    <div className='bg-arrow-main'>
                    <div className='bg-doublearrow'>
                            <h1> <MdDoubleArrow/> </h1>
                        </div> 
                    </div>
                    

                    <h1>4 BHK CONTEMPORARY STYLE HOUSE</h1>
                    <p>NELLAD , KERALA</p>
                </div>
                <div className='view-three-content-one'>
                    
                    <img src={projectsix} alt="" />
                    <div className='bg-arrow-main'>
                    <div className='bg-doublearrow'>
                            <h1> <MdDoubleArrow/> </h1>
                        </div> 
                    </div>
                    

                    <h1>4 BHK CONTEMPORARY STYLE HOUSE</h1>
                    <p>MUNNAR , KERALA</p>
                </div>
            </div>
            <div className='bg-link speci-bg-link'  >
              <Link className='view-link' to='https://instagram.com/lagombuilders?igshid=MzRlODBiNWFlZA==' >SEE OUR WORK</Link>
            </div>
        </div>
        
        
    </div>
  )
}

export default ViewThree