import React from 'react'
import buildingmain from './mumbai-skyline-seen-from-marine-drive-south-mumbai.jpg'
import './MovingOne.css'

function MovingText() {
  return (
    <div>
      <div className='moving-text-container' >
        <div className='display-bg'>
          <h1 className='moving-text'>LAGOM BUILDERS</h1>
          <h1 className='moving-text'>LAGOM BUILDERS</h1>
          <h1 className='moving-text'>LAGOM BUILDERS</h1>
          <h1 className='moving-text'>LAGOM BUILDERS</h1>
        </div>
        
        <div className='display-bg-left' >
          <h1 className='moving-textleft'>LAGOM BUILDERS</h1>
          <h1 className='moving-textleft'>LAGOM BUILDERS</h1>
          <h1 className='moving-textleft'>LAGOM BUILDERS</h1>
          <h1 className='moving-textleft'>LAGOM BUILDERS</h1>
        </div>
        
      </div>
        
        <div className='building-container'>
            <img src={buildingmain} alt="" />
        </div>
    </div>
  )
}

export default MovingText