import React from 'react'
import './ViewOne.css'
import { Link } from 'react-router-dom'
import imageview from './[removal.ai]_2889a29b-3b39-4b96-9c0b-91a5ff9e9e73-3d-rendering-isometric-house.png'

function ViewOne() {
  return (
    <div className='view-one'>
      <div className='view-one-container'>
        <div className='view-one-main'>
            <h1 className="view-text outline">DREAM IT </h1>
            <h1 className="view-text "> DESIGN IT</h1>
            <h1 className="view-text outline">BUILD IT</h1>
            
        </div>
        
      </div>
        
    </div>
  )
}

export default ViewOne