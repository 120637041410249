import React from 'react'
import Header from '../Components/Header/Header'
import TeamInfo from '../Components/teamInfo/TeamInfo'
import ViewFive from '../Components/ViewFive/ViewFive'
import Footer from '../Components/Footer/Footer'





function Team() {
  return (
    <div>
        <Header/>
        <TeamInfo/>
        <ViewFive/>
        <Footer/>
        
    </div>
  )
}

export default Team