import React from 'react'
import './ViewFive.css'
import videone from './1110256_Animation_Stars_Earth_3840x2160 (1).mp4'
import {Link} from 'react-router-dom'

function ViewFive() {
  return (
    <div className='view-five-main'>
        
            
                <div className="video-conatiner">
                    <video className="bg-video"  autoPlay muted loop  playsInline 
                        onClick={(e) => e.target.play()} >
                            
                        <source src={videone} type="video/mp4" />
                    </video>
                </div>
                <div className='bg-container-center'>
                    <div>
                        <div className='view-five-head'>
                            <h1 className='head-outline'>READY  TO  START</h1>
                            <div className='row-two-head'>
                                <h1 className='head-outline'>YOUR</h1> 
                                <h1 className='head-nooutline'>PROJECT ?</h1>
                            </div>
                        
                        </div>
                        <div className='margin-main-bg'>
                            <div className='margin-bg-five-button'>
                                <Link className='button-earth-five' to='/contact' > GET IN TOUCH </Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            
        
        

        
    </div>
  )
}

export default ViewFive