import React from 'react'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
import ContactPageSection from '../Components/Contact/ContactOne'


function Contact() {
  return (
    <div>
      
      <Header/>
      <ContactPageSection/>
      <Footer/>
    </div>
  )
}

export default Contact