import React from 'react'
import {Link } from 'react-router-dom'
import './Viewtwo.css'
import 'animate.css';
import { useInView } from 'react-intersection-observer';

function ViewTwo() {
  const [ref, inView] = useInView({
    triggerOnce: false, // Ensures the animation triggers only once
  });

  return (
    
    <div>
      
        <div className='view-two-main'>
          
          <div className='bg-yellow-vertical'></div>
          <div className='info-container'>

            <div ref={ref} className={`animated-element ${inView ? 'animate' : ''}`} >
              <h1 class="animate__animated animate__fadeInUp" >LAGOM BUILDERS : </h1>
            </div>
            <div ref={ref} className={`animated-element ${inView ? 'animate' : ''}`} >
              <p class="animate__animated animate__fadeInUp" >With over 5 years of experience in the field, our team has proudly 
              served more than 100 happy customers throughout Kerala. Our dedication 
              to excellence extends to every corner of the state, ensuring that our clients 
              receive the highest quality service.</p>
            </div>
            
            <div ref={ref} className={`animated-element ${inView ? 'animate' : ''}`} >
              <div className='button-bg-black animate__animated animate__fadeInUp'>
                <Link className='more-content ' to='/team' >ABOUT OUR TEAM  </Link>
              </div>
            </div>
              
              
          </div>
      </div>
    </div>
    
  )
}

export default ViewTwo