import React from 'react'
import Header from '../Components/Header/Header'
import ViewOne from '../Components/ViewOne/ViewOne'
import ViewTwo from '../Components/Viewtwo/ViewTwo'
import ViewThree from '../Components/Viewthree/ViewThree'
import ViewFour from '../Components/ViewFour/ViewFour'
import MovingText from '../Components/MovingText/MovingText'
import ViewFive from '../Components/ViewFive/ViewFive'
import Footer from '../Components/Footer/Footer'
import ContactPageSection from '../Components/Contact/ContactOne'



function Home() {
  return (
    <div>
        
        <Header/>
        <ViewOne/>
        <ViewTwo/>
        <ViewThree/>
        <MovingText/>
        <ViewFour/>
        <ContactPageSection/>
        <ViewFive/>
        <Footer/>
    </div>
  )
}

export default Home