import React from 'react'
import Header from '../Components/Header/Header'
import ViewFive from '../Components/ViewFive/ViewFive'
import Footer from '../Components/Footer/Footer'
import '../Components/Welcome/WelcomePage.css'
import ViewTwo from '../Components/Viewtwo/ViewTwo'

function About() {
  return (
    <div>
      <Header/>
      <div className='welcome-main'>
          <div className='bg-center-welcome'>
            <h1 className="view-text outline">GET TO</h1>
            <h1 className="view-text ">KNOW  </h1>
            <h1 className="view-text outline">ABOUT US</h1>
          </div>
              
          
        </div>
      <ViewTwo/>
      <ViewFive/>
      <Footer/>
    </div>
  )
}

export default About