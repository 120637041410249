import React from 'react'
import Header from '../Components/Header/Header'
import ViewFive from '../Components/ViewFive/ViewFive'
import Footer from '../Components/Footer/Footer'
import ViewThree from '../Components/Viewthree/ViewThree'


function Projects() {
  return (
    <div>
      
      
      <Header/>
      <ViewThree/>
      <ViewFive/>
      <Footer/>
    </div>
  )
}

export default Projects