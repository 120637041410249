import React from 'react'
import footerlogo from './logo png (1).png'
import './Footer.css'
import { BsFacebook,BsWhatsapp,BsInstagram,BsPinterest } from "react-icons/bs";



function Footer() {
  return (
    <div>
        <section class="contact-area" id="contact">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="contact-content text-center">
                            <img src={footerlogo} alt="" />
                            <div class="hr"></div>
                            <h6>Opp. T.m Seethi petrol pumb ,One way junction,Market (P.O),Muvattupuzha</h6>
                            <h6>+91 8714367699<span>|</span>+91 8129745445  <br/> +91 8089148467</h6>
                            <div class="contact-social">
                                <ul>
                                    <li><a class="hover-target" href=""> <BsFacebook/> </a></li>
                                    <li><a class="hover-target" href="https://instagram.com/lagombuilders?igshid=MzRlODBiNWFlZA=="> <BsInstagram/> </a></li>
                                    <li><a class="hover-target" href="https://api.whatsapp.com/send/?phone=%2B918714367699&text=Hello+there%2C+I+have+a+question.&type=phone_number&app_absent=0"> <BsWhatsapp/> </a></li>
                                    <li><a class="hover-target" href=""> <BsPinterest/> </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='copy-text-foot'>
                            <hr/>
                            <h3 >Copyright © 2023 Lagom builders. All rights reserved.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        

    </div>
  )
}

export default Footer