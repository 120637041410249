import React from 'react'
import './TeamInfo.css'
import teamImageOne from './d8794846-3657-490f-8ad2-b65112044ee9.jpg'
import teamImageTwo from './593d4c4d-a5fd-41e5-a161-7084fe91cba9.jpg'
import teamImageThree from './79c2a873-4bc6-4255-af7f-669c4a83e8d6.jpg'
import { BsFacebook,BsWhatsapp,BsInstagram } from "react-icons/bs";



function TeamInfo() {
  return (
    <div>
        <section className='bg-team-page'>
            <section class="team-page-section">
                <div class="container">
                    
                    <div class="sec-title centered">
                        <div class="title-one-team">Our Team</div>
                        <div class="separator"><span></span></div>
                        <h2>The Pillars of Lagom Builders</h2>
                    </div>

                    <div class="row clearfix">

                        <div class="team-block col-lg-4 col-md-6 col-sm-12">
                            <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <ul class="social-icons">
                                    <li><a  href=""> <BsFacebook/> </a></li>
                                    <li><a  href=""> <BsInstagram/> </a></li>
                                    <li><a  href=""> <BsWhatsapp/> </a></li>

                                    
                                </ul>
                                <div class="image">
                                    <a href="#"><img src={teamImageOne} alt="" /></a>
                                </div>
                                <div class="lower-content">
                                    <h3>Sadiq Basheer </h3>
                                    <div class="designation">Architect </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="team-block col-lg-4 col-md-6 col-sm-12">
                            <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <ul class="social-icons">
                                    <li><a  href=""> <BsFacebook/> </a></li>
                                    <li><a  href=""> <BsInstagram/> </a></li>
                                    <li><a  href=""> <BsWhatsapp/> </a></li>

                                    
                                </ul>
                                <div class="image">
                                    <a href="#"><img src={teamImageTwo} alt="" /></a>
                                </div>
                                <div class="lower-content">
                                    <h3>Muhammad Azahr</h3>
                                    <div class="designation">Civil Engineer</div>
                                </div>
                            </div>
                        </div>
                       
                        <div class="team-block col-lg-4 col-md-6 col-sm-12">
                            <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <ul class="social-icons">
                                    <li><a  href=""> <BsFacebook/> </a></li>
                                    <li><a  href=""> <BsInstagram/> </a></li>
                                    <li><a  href=""> <BsWhatsapp/> </a></li>

                                    
                                </ul>
                                <div class="image">
                                    <a href="#"><img src={teamImageThree} alt="" /></a>
                                </div>
                                <div class="lower-content">
                                    <h3> Abdul Risvan </h3>
                                    <div class="designation"> Structural Engineer </div>
                                </div>
                            </div>
                        </div>
                        
                        

                    </div>
                </div>
            </section>
        </section>
    </div>
  )
}


export default TeamInfo