import React from 'react';
import './ContactOne.css'
import { db } from '../../firebaseConfig';
import { useState } from 'react';
import { addDoc,collection } from 'firebase/firestore';
import { FaLocationDot } from "react-icons/fa6";
import { AiTwotoneMail } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";


const ContactPageSection = () => {

  const [name,setName]=useState('');
  const [email,setEmail]=useState('');
  const [subject,setSubject]=useState('');
  const [phone,setPhone]=useState('');
  const [message,setMessage]=useState('');

  const userCollectionRef=collection(db ,"contactdata")

  const handleSubmit =(e)=>{
    
    e.preventDefault();

    

    addDoc(userCollectionRef,{
      Name:name,
      Email:email,
      Subject:subject,
      Phone:phone,
      Message:message

    }).then(()=>{
      if(!alert('Contact form submitted'))document.location ="https://contactform-aa785.web.app/"
    })
  }

  return (
    <section className="contact-page-section">
      <div className="container">
        <div className="sec-title">
          <div className="title">Contact Us</div>
          <h2>Let's Get in Touch.</h2>
        </div>
        <div className="inner-container">
          <div className="row clearfix">
            <div className="form-column col-md-8 col-sm-12 col-xs-12">
              <div className="inner-column">
                <div className="contact-form">
                  <form onSubmit={handleSubmit}   id="contact-form">
                    <div className="row clearfix">
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input type="text" name="name"  onChange={(event) => {
                          setName(event.target.value)
                        }} 
                        placeholder="Name" 
                        required
                         />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input type="email" name="email" onChange={(event) => {
                          setEmail(event.target.value)
                        }}  
                        
                        placeholder="Email" required />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input type="text" name="subject" onChange={(event) => {
                          setSubject(event.target.value)
                        }} 
                         placeholder="Subject" required  />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input type="text" name="phone"  onChange={(event) => {
                          setPhone(event.target.value)
                        }} 
                        placeholder="Phone" required />
                      </div>
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <textarea name="message" onChange={(event) => {
                          setMessage(event.target.value)
                        }} 
                        placeholder="Message" required></textarea>
                      </div>
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <button type="submit" onClick={handleSubmit} className="theme-btn btn-style-one">Send Now</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="info-column col-md-4 col-sm-12 col-xs-12">
              <div className="inner-column">
                <h2>Contact Info</h2>
                <ul className="list-info">
                  <li><i> <FaLocationDot/> </i> Opp. T.m Seethi petrol pumb ,One way junction,Market (P.O),Muvattupuzha </li>
                  <li><i> <AiTwotoneMail/> </i>connectlagom <br/> builders@gmail.com</li>
                  <li><i><BiSolidPhoneCall/>  </i>+91 8714367699<br />+91 8129745445 <br/> +91 8089148467 </li>
                </ul>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPageSection;
