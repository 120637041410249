import React from 'react'
import {BsFillCalendarCheckFill,BsShieldShaded,BsFillBagHeartFill} from "react-icons/bs";
import './Viewfour.css'
import { GiTrophy } from "react-icons/gi";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import {Link } from 'react-router-dom'

function ViewFour() {
  return (
    <div className='view-four-main'>
        <div className='view-four-details'>
            <h1>THE LAGOM IDEOLOGY</h1>
            <div className='margin-bg-info-four'>
                <p> ' we build your dream project within your budget '</p>
            </div>
            

        </div>

        <div className="view-four-row">
            <div className="view-four-container-one animate__animated animate__fadeInUp">
                <div className='icon-view-four'>
                    <h1> <GiTrophy/> </h1>
                </div>
                
                <h1>HIGH QUALITY</h1>
                <div>
                    <p>High-quality construction is the cornerstone of a safe, functional, 
                        and aesthetically pleasing built environment. It is the result of 
                        meticulous planning, skilled craftsmanship, and the use of superior materials.
                        High-quality construction also prioritizes the selection of top-grade materials.</p>
  
                </div>
            </div>
            <div className="view-four-container-one">
                <div className='icon-view-four'>
                    <h1> <FaMoneyCheckDollar/> </h1>
                </div>
                
                <h1>ON BUDGET</h1>
                <div>
                    <p>Budget is an essential aspect of any building project, whether it's a 
                        small home renovation or a large-scale commercial development. At its core, budget
                         construction focuses on achieving the desired outcome within financial constraints. 
                         This requires careful planning and cost estimation.</p>
  
                </div>
            </div>
            <div className="view-four-container-one">
                <div className='icon-view-four'>
                    <h1> <BsFillCalendarCheckFill/> </h1>
                </div>
                
                <h1>ON SCHEDULE</h1>
                <div>
                    <p>Completing construction projects on time is a critical aspect of project management in the construction industry. Timely project completion is essential for several reasons, including meeting client expectations, adhering to contractual agreements, and minimizing costs. </p>
  
                </div>
            </div>
                
        </div>
        <div className='view-four-two'>
            <div className="view-four-container-one">
                    <div className='icon-view-four'>
                        <h1> <BsShieldShaded/> </h1>
                    </div>
                    
                    <h1> SAFETY</h1>
                    <div>
                        <p>Safety in construction is a critical aspect of the industry that focuses on preventing accidents, injuries, and fatalities on construction sites. It encompasses a wide range of practices, regulations, and measures aimed at ensuring the well-being of workers, the public, and the environment. </p>
    
                    </div>
                </div>
                <div className="view-four-container-one">
                    <div className='icon-view-four'>
                        <h1> <BsFillBagHeartFill/> </h1>
                    </div>
                    
                    <h1>RELATIONSHIP</h1>
                    <div>
                        <p>Relationships in construction refer to the interactions and collaborations among various stakeholders involved in a construction project. These relationships are critical for the successful planning, execution, and completion of construction projects.</p>
    
                </div>
            </div>
        </div>
    </div>
  )
}

export default ViewFour