import React from 'react'
import Header from '../Components/Header/Header'
import ViewFive from '../Components/ViewFive/ViewFive'
import Footer from '../Components/Footer/Footer'
import Carousel from '../Components/Services/ServicesOne'
import './Services.css'
import { MdAnalytics } from "react-icons/md";
import { IoLogoDesignernews } from "react-icons/io5";
import { GrCatalog,GrUserManager } from "react-icons/gr";






function Services() {
  return (
    <div>
        
        <Header/>
        <div className='welcome-main'>
          <div className='bg-center-welcome'>
            <h1 className="view-text outline">HOW CAN </h1>
            <h1 className="view-text ">LAGOM </h1>
            <h1 className="view-text outline">HELP YOU</h1>
          </div>
              
          
        </div>
        <Carousel/>
        
          <div className="view-four-row specific-services">
            <div>
                  <h1 className='head-carousal-main services-clone'>Our Services</h1>
                <div className="view-four-container-one animate__animated animate__fadeInUp">
                    <div className='icon-view-four black-clone-serv'>
                        <h1> <MdAnalytics/> </h1>
                    </div>
                    
                    <h1>ARCHITECTURE</h1>
                    <div>
                        <p>Architecture is a multifaceted discipline encompassing the art 
                            and science of designing and constructing structures, whether
                            physical buildings, digital systems, or urban environments.
                            It involves the careful planning and organization of complex
                            systems to meet specific goals.</p>
      
                    </div>
                </div>
                <div className="view-four-container-one">
                    <div className='icon-view-four black-clone-serv'>
                        <h1> <IoLogoDesignernews/> </h1>
                    </div>
                    
                    <h1>INTERIOR DESIGN</h1>
                    <div>
                        <p>Interior design is the art and science of enhancing the interior 
                          of a space to create a harmonious and aesthetically pleasing environment.
                          It goes beyond mere decoration, encompassing a wide range of elements.</p>
      
                    </div>
                </div>
                <div className="view-four-container-one">
                    <div className='icon-view-four'>
                        <h1> <GrCatalog/> </h1>
                    </div>
                    
                    <h1>PLANNING</h1>
                    <div>
                        <p>Construction planning is a fundamental aspect of any successful construction project,
                          serving as the roadmap that guides the entire endeavor from inception to completion.
                            This comprehensive process involves meticulous organization and coordination of various
                            tasks, resourcest.</p>
      
                    </div>
                </div>
                <div className="view-four-container-one">
                    <div className='icon-view-four'>
                        <h1> <GrUserManager/> </h1>
                    </div>
                    
                    <h1>CONSTRUCTUION <br /> MANAGEMENT</h1>
                    <div>
                        <p>Construction management is a crucial discipline that plays a pivotal role in the successful 
                          execution of construction projects. It encompasses a range of responsibilities and tasks aimed 
                          at overseeing the planning.</p>
      
                    </div>
                </div>
            </div>
            
                
          </div>
        <ViewFive/>
        <Footer/>
    </div>
  )
}

export default Services