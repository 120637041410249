import React from 'react';
import './ServicesOne.css'

import { BsFillArrowRightSquareFill,BsFillArrowLeftSquareFill } from "react-icons/bs";


function Carousel(){
  
    return (
      
        <div className="container-text-center">
            <div id="carouselExampleIndicators" className="carousel slide">
                <div>
                  <div>
                  <h1 className='head-carousal-main'>Our Services</h1>
                </div>
                <div className='bg-margin-carousal'>
                  <div>
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="3"
                        aria-label="Slide 4"
                      ></button>
                    </div>
                    <div className="carousel-inner">
                      <div className="carousel-item active bg-caro-one">
                        
                        <h1>ARCHITECTURE</h1>
                        <p>Architecture is a multifaceted discipline encompassing the art 
                            and science of designing and constructing structures, whether
                            physical buildings, digital systems, or urban environments.
                            It involves the careful planning and organization of complex
                            systems to meet specific goals while considering various 
                            constraints and principles.
                          </p>
                        
                      </div>
                      <div className="carousel-item bg-caro-two">
                      <h1>INTERIOR DESIGN</h1>
                        <p>Interior design is the art and science of enhancing the interior 
                          of a space to create a harmonious and aesthetically pleasing environment.
                          It goes beyond mere decoration, encompassing a wide range of elements,
                            including color schemes, furniture selection, layout, lighting, and overall spatial arrangement.
                          </p>
                      </div>
                      <div className="carousel-item bg-caro-three">
                      <h1>PLANNING</h1>
                        <p>Construction planning is a fundamental aspect of any successful construction project,
                          serving as the roadmap that guides the entire endeavor from inception to completion.
                            This comprehensive process involves meticulous organization and coordination of various
                            tasks, resources, and timelines to ensure that the project is executed efficiently, safely, and within budget.
                          </p>
                      </div>
                      <div className="carousel-item bg-caro-four">
                      <h1>CONSTRUCTUION <br /> MANAGEMENT</h1>
                        <p>Construction management is a crucial discipline that plays a pivotal role in the successful 
                          execution of construction projects. It encompasses a range of responsibilities and tasks aimed 
                          at overseeing the planning, coordination, and execution of construction activities from inception to completion.
                          
                          </p>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="prev"
                      style={{color:"yellow"}}
                    >
                      <h1> <BsFillArrowLeftSquareFill/> </h1>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="next"
                      style={{color:"yellow"}}
                      
                    >
                      <h1> <BsFillArrowRightSquareFill/> </h1>
                    </button>
                  </div>
                </div>
                </div>
                
                
            
              </div>

              
                    
            </div>




         
          
      
     
      
          
    );
  }


export default Carousel;