import React,{useState,useEffect} from 'react'
import './Header.css'
import {Link} from 'react-router-dom'
import logoone from './logoomlogo.jpg'

import { BsFacebook,BsWhatsapp,BsInstagram } from "react-icons/bs";


function Header() {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      // Function to handle scroll event
      const handleScroll = () => {
        if (window.scrollY >= 100) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      // Attach the scroll event listener
      window.addEventListener('scroll', handleScroll);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    
  return (
    <div>
        <div className='blacktop'>
            {/* <div className='image-container'>
                <img src={vehicleone} alt="" />
                <img src={vehicletwo} alt="" />
                <img src={vehiclethree} alt="" />
                <img src={vehiclefour} alt="" />
                <img src={vehiclefive} alt="" />
                <img src={vehiclesix} alt="" />
                <img src={vehicleseven} alt="" />
                <img src={vehicleeight} alt="" />
                <img src={vehicleone} alt="" />
                
            </div> */}
        </div>
        <nav className={`navbar navbar-expand-lg ${isScrolled ? 'fixed-navbar' : ''}`}>
            <div class="container-fluid">
                <a class="navbar-brand"  ><img className='navbar-logo' src={logoone} alt="" /></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <Link class="nav-link " aria-current="page" to="/">HOME</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link false-specific " to='/about' >ABOUT US</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link false-specific " to='https://instagram.com/lagombuilders?igshid=MzRlODBiNWFlZA==' >PROJECTS</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link false-specific " to='/services' >SERVICES</Link>
                        </li>
                        
                        <li class="nav-item">
                            <Link class="specific nav-link  " to='/contact' >CONTACT US</Link>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </nav>

        <div class=" social-specific">
          <div className='right-position-fixed' >
              <ul className='inline-social' >
                <li><a class="hover-target" href="https://api.whatsapp.com/send/?phone=%2B918714367699&text=Hello+there%2C+I+have+a+question.&type=phone_number&app_absent=0"> <BsWhatsapp/> </a></li>                       
                <li><a class="hover-target" href="https://instagram.com/lagombuilders?igshid=MzRlODBiNWFlZA=="> <BsInstagram/> </a></li>
                <li><a class="hover-target" href=""> <BsFacebook/> </a></li>
              </ul> 
          </div>
                                 
        </div>
    </div>
  )
}

export default Header