import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyDxUAJzhzvJ27i6p75K_-dfebIy1dhx2r4",
  authDomain: "contactform-aa785.firebaseapp.com",
  projectId: "contactform-aa785",
  storageBucket: "contactform-aa785.appspot.com",
  messagingSenderId: "332081369529",
  appId: "1:332081369529:web:11ecc501ede1c361ab6668"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);


