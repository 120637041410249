import './App.css';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Home from './Pages/Home';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import Team from './Pages/Team';
import ScrollToTop from './Pages/scroll';



function App() {
  return (
    <div className="App">
      <Router>
        
        <ScrollToTop/>
        <Routes>
          
          <Route path=""  element={<Home/>} ></Route>
          
          <Route path="/about" element={<About/>} ></Route>
          
          <Route path="/projects" element={<Projects/>} ></Route>
          
          <Route path="/services" element={<Services/>} ></Route>
          
          <Route path="/contact" element={<Contact/>} ></Route>
          
          <Route path="/team" element={<Team/>} ></Route>
        </Routes>
      </Router>

      
    </div>
  );
}



export default App;
